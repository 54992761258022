import React from 'react';
import PropTypes from 'prop-types';
import FilterSection from 'Components/FilterComponents/FilterSection';
import { ENV_ENVIRONMENT } from 'Common/Helpers/ApiHelper';
import langValues from 'Config/i18n/index';
import MaterialButton from 'Components/Atoms/MaterialButton/MaterialButton';
import FilterPopover from 'Components/Molecules/FilterPopover/FilterPopover';
import { ReactComponent as FilterIcon } from 'Common/Assets/filter.svg';
import { roleConsts, trackedButtons, trackedEvents } from 'Utils/consts';
import { trackEvent } from 'Utils/analytics';
import { Dealer, User } from 'Common/Helpers/AuthHelper';
import FilterSectionDealer from 'Components/FilterComponents/FilterSectionDealer/FilterSectionDealer';
import getFilterPanels from './getFilterPanels';

const FilterAccordion = ({
  models,
  selectedFilters,
  setAppliedFilters,
  filterPlacement,
  setDelayAppliedFilters,
  handleConfirm,
  selectedDelayFilters,
  clearFilters,
  isPageDelayManamegent,
  appliedDelayFilters,
  appliedFilters,
  setSelectedFilters,
  setDelaySelectedFilters,
  dealerId,
  setDealerIdFilter,
}) => {
  let filters;
  let filtersApplied;
  let setFiltersSelected;
  let setFiltersApplied;
  if (isPageDelayManamegent) {
    filters = { ...selectedDelayFilters };
    filtersApplied = { ...appliedDelayFilters };
    setFiltersSelected = setDelaySelectedFilters;
    setFiltersApplied = setDelayAppliedFilters;
  } else {
    filters = { ...selectedFilters };
    filtersApplied = { ...appliedFilters };
    setFiltersSelected = setSelectedFilters;
    setFiltersApplied = setAppliedFilters;
  }

  const [panels, setPanels] = React.useState(getFilterPanels(isPageDelayManamegent, models));
  if (!panels || !filters) return null;

  const setPanelModels = () => {
    const panelModels = panels.filter((panel) => panel.panelKey === 'models')[0].panelItems;
    if (Object.keys(panelModels).length !== models.data.length) {
      // eslint-disable-next-line
      let modelsFormattedObj = {};
      // eslint-disable-next-line
      models.data.map(({ modelId, modelName }) => {
        modelsFormattedObj[modelId] = modelName;
      });
      const newPanels = panels.map((panel) => {
        if (panel.panelKey === 'models') {
          // eslint-disable-next-line
          panel.panelItems = modelsFormattedObj;
        }

        return panel;
      });

      setPanels(newPanels);
    }
  };

  const getBrands = () => {
    const removeRolesFromAnotherEnviroment = ENV_ENVIRONMENT === 'BR' ? 'AR' : 'BR';
    const roles = User.get()
      ? User.get().userProfile.filter((role) => !role.includes(`_${removeRolesFromAnotherEnviroment}`))
      : '';
    let brandsDefault =
      ENV_ENVIRONMENT === 'BR'
        ? {
            fiat: 'FIAT',
            jeep: 'JEEP',
            ram: 'RAM',
            leapMotors: 'LEAP MOTORS',
          }
        : {
            fiat: 'FIAT',
            jeep: 'JEEP',
            ram: 'RAM',
            abarth: 'ABARTH',
            lancia: 'LANCIA',
            alfaRomeo: 'ALFA ROMEO',
            masseratti: 'MASSERATTI',
            leapMotors: 'LEAP MOTORS',
          };

    const hasRoleBr = roles.includes(roleConsts.br);
    const hasRoleAr = roles.includes(roleConsts.ar);

    if (!hasRoleBr && ENV_ENVIRONMENT === 'BR') {
      brandsDefault = {};
    }
    if (!hasRoleAr && ENV_ENVIRONMENT === 'AR') {
      brandsDefault = {};
    }

    if (User && User.canSeeCitroen()) {
      brandsDefault.ds = 'DS';
      brandsDefault.citroen = 'CITROEN';
    }
    if (User && User.canSeePeugeot()) {
      brandsDefault.peugeot = 'PEUGEOT';
    }
    const sortable = Object.fromEntries(Object.entries(brandsDefault).sort());

    return sortable;
  };

  const setPanelBrands = () => {
    const newPanels = panels.map((panel) => {
      if (panel.panelKey === 'brands') {
        // eslint-disable-next-line
        panel.panelItems = getBrands();
      }

      return panel;
    });

    setPanels(newPanels);
  };
  React.useEffect(() => {
    setPanelBrands();
    setPanelModels();
  }, [isPageDelayManamegent, models]);

  const [expandedFilterKey, setExpandedFilterKey] = React.useState(null);
  const [selectedDealerId, setSelectedDealerId] = React.useState(dealerId);

  const isDealer = Boolean(Dealer.get());

  const selectAll = (panelKey, keys) => {
    const areAllSelected = filters[panelKey].length === keys.length;
    let newFilters;

    if (areAllSelected) {
      newFilters = [];
    } else {
      newFilters = keys;
    }

    setFiltersSelected({
      ...filters,
      [panelKey]: newFilters,
    });
  };

  const selectItem = (panelKey, key) => {
    const isSelected = filters[panelKey].includes(key);

    let newSelected;

    if (isSelected) {
      newSelected = filters[panelKey].filter((item) => item !== key);
    } else {
      newSelected = [...filters[panelKey], key];
    }
    setFiltersSelected({
      ...filters,
      [panelKey]: newSelected,
    });
  };

  const onSetExpandedFilterKey = (filterKey) => {
    const newExpandedFilterKey = expandedFilterKey === filterKey ? null : filterKey;
    setExpandedFilterKey(newExpandedFilterKey);
  };

  const createMixPanelFilterEvent = () => {
    const macroFilters = [];

    Object.entries(filters).forEach(([key, value]) => {
      if (value.length > 0) {
        macroFilters.push(key);
      }
    });

    const pendenciesFilter = filters.pendencies;
    const statusFilter = filters.status;
    const macroAreasFilter = filters.macroAreas;
    const macroCategoriesFilter = filters.macroCategories;
    const categoriesFilter = filters.categories;

    trackEvent(trackedEvents.filterApply, {
      filters: macroFilters,
      pendenciesFilter,
      categoriesFilter,
      statusFilter,
      macroAreasFilter,
      macroCategoriesFilter,
    });
  };

  const onApplyFilter = async () => {
    createMixPanelFilterEvent();
    setDealerIdFilter(selectedDealerId);
    await setFiltersApplied(filters);
    handleConfirm();
  };

  const handleClear = async () => {
    trackEvent(trackedEvents.buttonClick, { button: trackedButtons.clearFilters });
    await clearFilters();
    handleConfirm();
  };

  const renderDealerFilterSection = () => {
    if (isDealer || isPageDelayManamegent) return null;
    const panelKey = 'dealerId';

    return (
      <FilterSectionDealer
        dealerId={selectedDealerId}
        panelName={langValues.filterLabels.dealerId}
        panelKey={panelKey}
        isExpanded={expandedFilterKey === panelKey}
        setDealerId={setSelectedDealerId}
        setExpanded={onSetExpandedFilterKey}
      />
    );
  };

  const popoverContent = (setAnchorEl) => (
    <section>
      <div className="filterHeader">
        <h6 className="filterTitle">{langValues.filters}</h6>
      </div>
      <div className={`filterPanels ${ENV_ENVIRONMENT}`}>
        {renderDealerFilterSection()}
        {panels.map((panel) => {
          const selectedItems = filters[panel.panelKey];
          return (
            <FilterSection
              panel={panel}
              key={panel.panelKey}
              isExpanded={expandedFilterKey === panel.panelKey}
              setExpanded={onSetExpandedFilterKey}
              selectAll={selectAll}
              selectItem={selectItem}
              selected={selectedItems}
            />
          );
        })}
      </div>
      <div className="filterButton">
        <button
          type="button"
          className="cleanAll-text"
          onClick={() => {
            handleClear();
            setAnchorEl(null);
          }}
        >
          {langValues.clearSelection}
        </button>
        <MaterialButton
          onClick={() => {
            onApplyFilter();
            setAnchorEl(null);
          }}
          buttonText={langValues.apply}
          type="primary"
        />
      </div>
    </section>
  );

  const isFilterApplied =
    Object.values(filtersApplied).some((item) => item.length > 0) || (dealerId && dealerId.length > 0);

  const filterAccordionButtonText = isFilterApplied
    ? langValues.filterLabels.changeFilters
    : langValues.filterLabels.filterOrders;

  return (
    <FilterPopover
      IconLeft={FilterIcon}
      buttonText={filterAccordionButtonText}
      active={Boolean(isFilterApplied)}
      handleIconRightClick={handleClear}
      placement={filterPlacement}
      popoverContent={popoverContent}
    />
  );
};

FilterAccordion.propTypes = {
  models: PropTypes.object.isRequired,
  selectedFilters: PropTypes.object.isRequired,
  selectedDelayFilters: PropTypes.object.isRequired,
  appliedFilters: PropTypes.object.isRequired,
  appliedDelayFilters: PropTypes.object.isRequired,
  filterPlacement: PropTypes.string.isRequired,
  setSelectedFilters: PropTypes.func.isRequired,
  setDelaySelectedFilters: PropTypes.func.isRequired,
  setAppliedFilters: PropTypes.func.isRequired,
  setDelayAppliedFilters: PropTypes.func.isRequired,
  setDealerIdFilter: PropTypes.func.isRequired,
  handleConfirm: PropTypes.func.isRequired,
  clearFilters: PropTypes.func.isRequired,
  isPageDelayManamegent: PropTypes.bool.isRequired,
  dealerId: PropTypes.string.isRequired,
};

export default FilterAccordion;
