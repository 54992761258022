import { connect } from 'react-redux';

import { loadAuth, loadAuthFailed } from 'Infrastructure/Ducks/Auth';
import UserEntry from './UserEntry';

const mapStateToProps = (state) => {
  const { loginError } = state.auth;

  return {
    loginError,
  };
};

const mapDispatchToProps = (dispatch) => ({
  loadAuth: (data) => {
    dispatch(loadAuth(data));
  },
  loadAuthFailed: (error) => {
    dispatch(loadAuthFailed(error));
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(UserEntry);

